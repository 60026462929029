import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.style.css'
import { isMobile } from 'react-device-detect';
import Feed from '../../../components/Feed/Feed';
import AppStore from '../../../components/AppStore/AppStore';
import LoginForm from '../../../components/LoginForm/LoginForm';
import { useTranslation } from 'react-i18next';
import playstore from 'assets/images/playstore.png';
import appstore from 'assets/images/appstore.png';
import appgallery from 'assets/images/appgallery.png';


const Login = () => {

  const { t } = useTranslation()

  /* RENDER MOBILE */
  if (isMobile) {
    return (
      <div className='mobile-login'>

        <div className='store'>
          <span className='title'>
            {t('download_app')} <br></br>
            {t('app_name')}
          </span>
          <div className='container-logo'>
            <a href="https://cgrae.socialhub.ci/download/ips-cgrae-social-hub.apk">
              <img className='img-playstore' src={playstore} alt='' />
            </a>
            <a href="https://apps.apple.com/us/app/testflight/id899247664">
              <img className='img-appstore' src={appstore} alt='' />
            </a>
            <a href="https://cgrae.socialhub.ci/download/ips-cgrae-social-hub.apk">
              <img className='img-appgallery' src={appgallery} alt='' />
            </a>
          </div>
        </div>

        <LoginForm />

      </div>
    )
  }


  return (
    <div className='login'>

      {/* RENDER SOTRE */}
      <div className='card-store'>
        <AppStore />
      </div>

      {/* RENDER OPINION */}
      <div className='card-opinion'>
        <Feed />
      </div>

      {/* RENDER LOGIN */}
      <div className='card-login'>
        <LoginForm />
      </div>

    </div >
  )
}

export default Login